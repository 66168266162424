import React from 'react';
import './Hero.css';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import VideoSrc from '../../media/background.mp4'

function Hero() {
  return (
    <section className="hero-section">
      <Navbar />
      <div className="video-container">
        <video autoPlay muted loop playsInline>
          <source src={VideoSrc} type="video/mp4" />
        </video>
      </div>
      <div className="hero-content">
        <h1 className="hero-title">Coming soon.</h1>
      </div>
      <Footer/>
    </section>
  );
}

export default Hero;
