import React from 'react';
import './Footer.css'

function Footer() {
  return (
    <footer className="footer">
      <p>© 2023 Oberii. All rights reserved.</p>
    </footer>
  );
}

export default Footer;