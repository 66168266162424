import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import Hero from './components/hero/Hero';

function App() {

  return (
    <Router>
      <Hero/>
    </Router>
  );
}

export default App;
